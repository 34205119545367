import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type IRoute from '@src/@types/IRoute';
import type { IGlobalState } from '@src/reducers/index';

const initialState: IRoute = {
  hasUtmLabel: undefined,
};

export const setRouteState = createAction<IRoute>('route/set');

export const routeReducer = createReducer({}, initialState).on(setRouteState, (_, data) => data);

export const getHasUtmLabel = (state: IGlobalState) => state.route.hasUtmLabel;

export const useHasUtmLabel = () => useSelector(getHasUtmLabel);
