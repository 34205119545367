import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type IHeader from '@src/@types/IHeader';
import type { IGlobalState } from '@src/reducers/index';

const initialState: IHeader = {
  hasBot: undefined,
  userAgent: undefined,
};

export const setHeaderState = createAction<IHeader>('header/set');

export const headerReducer = createReducer({}, initialState).on(setHeaderState, (_, data) => data);

const getHasBot = (state: IGlobalState) => state.header.hasBot;

export function useHasBot() {
  return useSelector(getHasBot);
}
