import { v4 as uuid } from 'uuid';

import type { IAdvertising } from '@src/@types/advertising';
import type { List } from '@src/@types/microcredits';

import { COLOR } from '../constants/button';

export const MONETIZATION_INTERNAL = 'growth-internal';
const getAdvertisingButtonColor = (advertising: IAdvertising) => (advertising.isPartner ? COLOR.ORANGE : COLOR.GREEN);

export const getOffersWithAdvertising = (items: List.IMicrocredit[]) => ({
  items: items.map((item) => {
    if (item.advertising) {
      return {
        ...item,
        advertising: {
          ...item.advertising,
          buttonColor: getAdvertisingButtonColor(item.advertising),
          advSub: uuid(),
        },
      };
    }

    return item;
  }),
});
