import type { IGlobalState } from '../reducers';
import { isServer } from '../utils';

import { initializeStore } from './initializeStore';
import type { ReduxStore } from './types';

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

/**
 * Всегда создаёт новый store для сервера
 * Если store не доступен для клиента, создаёт его и записывает в window
 * @param initialState
 */
export const getOrCreateStore = (initialState?: IGlobalState): ReduxStore => {
  if (isServer) {
    return initializeStore(initialState);
  }

  if (!window.__NEXT_REDUX_STORE__) {
    window.__NEXT_REDUX_STORE__ = initializeStore(initialState);
  }

  return window.__NEXT_REDUX_STORE__;
};
