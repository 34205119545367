import { DAYS_IN_WEEK } from 'entities/FullDeal/config';
import { TERM_MIN } from 'features/FullDeal/config';

export const FULL_DEAL_FORM_AMOUNT_DEFAULT = 14_000;
export const FULL_DEAL_FORM_TERM_DEFAULT = 21;

// @todo: индексы дефолтных значений
export const DEFAULT_TERM_FOR_FIRST_RANGE = 21;
export const DEFAULT_TERM_FOR_SECOND_RANGE = 21;
export const DEFAULT_TERM_FOR_THIRD_RANGE = 14;
export const DEFAULT_TERM_FOR_FOURTH_RANGE = 48;

/**
 * @todo: значения в днях
 * Данные значения показываются если выбрана сумма займа до 14_000
 * Если выбрана сумма займа от 15_000 до 31_000 то сначала в слайдере отображаются значения в днях,
 * а потом в неделях в интервале от 10 до 24;
 */
export const TERM_SLIDER_MAX_FOR_FIRST_RANGE = 33;
export const AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD = 15_000;

export const TERM_SLIDER_REAL_VALUES_FOR_FIRST_RANGE = Object.fromEntries(
  Array.from({ length: TERM_SLIDER_MAX_FOR_FIRST_RANGE + 1 - TERM_MIN }, (_, i) => [
    i + TERM_MIN,
    {
      value: i + TERM_MIN,
      isDays: true,
      visibleValue: i + TERM_MIN,
    },
  ]),
);

/**
 * @todo: значения в неделях
 *  Данные значения показываются если выбрана сумма займа от 15_000 до 70_000
 *  Если выбрана сумма займа от 15_000 до 30_000 то сначала в слайдере отображаются значения в днях
 *  в интервале  от 5 до 33 , а потом в неделях
 *
 * Так же данный интервал в неделях работает с выбранной суммой займа от 31_000 до 70_000
 */
// дни
export const TERM_SLIDER_MIN_SECOND_RANGE = 5;
export const AMOUNT_SLIDER_SECOND_RANGE_THRESHOLD = 31_000;
// недели
export const TERM_SLIDER_MIN_THIRD_RANGE = 10;
export const TERM_SLIDER_MAX_THIRD_RANGE = 24;

// @todo: значения слайдера в днях
const SECOND_RANGE_SLIDER_VALUES_FOR_DAYS = Object.fromEntries(
  Array.from({ length: TERM_SLIDER_MAX_FOR_FIRST_RANGE + 1 - TERM_SLIDER_MIN_SECOND_RANGE }, (_, i) => [
    i + TERM_SLIDER_MIN_SECOND_RANGE,
    {
      value: i + TERM_SLIDER_MIN_SECOND_RANGE,
      isDays: true,
      visibleValue: i + TERM_SLIDER_MIN_SECOND_RANGE,
    },
  ]),
);

// @todo: значения слайдера в неделях
const SECOND_RANGE_SLIDER_VALUES_FOR_WEEKS = Object.fromEntries(
  Array.from({ length: (TERM_SLIDER_MAX_THIRD_RANGE + 2 - TERM_SLIDER_MIN_THIRD_RANGE) / 2 }, (_, idx) => {
    const visibleValue = idx ? 2 * idx + TERM_SLIDER_MIN_THIRD_RANGE : idx + TERM_SLIDER_MIN_THIRD_RANGE;

    return [
      TERM_SLIDER_MAX_FOR_FIRST_RANGE + idx + 1,
      {
        value: visibleValue * DAYS_IN_WEEK,
        isDays: false,
        visibleValue,
      },
    ];
  }),
);

export const TERM_SLIDER_REAL_VALUES_FOR_SECOND_RANGE = {
  ...SECOND_RANGE_SLIDER_VALUES_FOR_DAYS,
  ...SECOND_RANGE_SLIDER_VALUES_FOR_WEEKS,
};

export const TERM_SLIDER_REAL_VALUES_FOR_THIRD_RANGE = Object.fromEntries(
  Array.from({ length: (TERM_SLIDER_MAX_THIRD_RANGE + 2 - TERM_SLIDER_MIN_THIRD_RANGE) / 2 }, (_, idx) => {
    const visibleValue = idx ? 2 * idx + TERM_SLIDER_MIN_THIRD_RANGE : idx + TERM_SLIDER_MIN_THIRD_RANGE;

    return [
      idx + TERM_SLIDER_MIN_THIRD_RANGE,
      {
        value: visibleValue * DAYS_IN_WEEK,
        isDays: false,
        visibleValue,
      },
    ];
  }),
);

/**
 * @todo: значения в неделях
 *  Данные значения показываются если выбрана сумма займа от 71_000 до 100_000
 */
export const TERM_SLIDER_MIN_FOURTH_RANGE = 46;
export const TERM_SLIDER_MAX_FOURTH_RANGE = 52;
export const AMOUNT_SLIDER_FOURTH_RANGE_THRESHOLD = 71_000;

export const TERM_SLIDER_REAL_VALUES_FOR_FOURTH_RANGE = Object.fromEntries(
  Array.from({ length: (TERM_SLIDER_MAX_FOURTH_RANGE + 2 - TERM_SLIDER_MIN_FOURTH_RANGE) / 2 }, (_, idx) => {
    const visibleValue = idx ? 2 * idx + TERM_SLIDER_MIN_FOURTH_RANGE : idx + TERM_SLIDER_MIN_FOURTH_RANGE;

    return [
      idx + TERM_SLIDER_MIN_FOURTH_RANGE,
      {
        value: visibleValue * DAYS_IN_WEEK,
        isDays: false,
        visibleValue,
      },
    ];
  }),
);
