import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import { useIsMobile } from '@sravni/react-utils';
import type { IBranding, IConfig } from '@sravni/types/lib/branding';

import type StatusData from '@src/@types/StatusData';

import DataStatus from '../enums/DataStatus';

import type { IGlobalState } from '.';

type IState = StatusData<IBranding | null> & {
  scrolledDown: boolean;
};

const initialState: IState = {
  data: null,
  status: DataStatus.Default,
  scrolledDown: false,
};

export const setBranding = createAction<Partial<IState>>('branding/set');
export const setScrolledDown = createAction<IState['scrolledDown']>('branding/scrolledDown/set/');

export const brandingReducer = createReducer<IState>({}, initialState)
  .on(setBranding, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(setScrolledDown, (state, payload) => ({
    ...state,
    scrolledDown: payload,
  }));

const getBranding = (state: IGlobalState) => state.branding.data;
const getIsScrolledDown = (state: IGlobalState) => state.branding.scrolledDown;
const getDesktopBranding = (state: IGlobalState) => {
  const data = getBranding(state);

  return data?.desktop;
};

const getMobileBranding = (state: IGlobalState) => {
  const data = getBranding(state);

  return data?.mobile;
};

const getPixelsBranding = (state: IGlobalState) => state?.branding?.data?.pixels;

export function useBranding(): IState['data'] {
  return useSelector<IGlobalState, IState['data']>(getBranding);
}
export function useDesktopBranding(): IConfig | undefined {
  return useSelector<IGlobalState, IConfig | undefined>(getDesktopBranding);
}
export function useMobileBranding(): IConfig | undefined {
  return useSelector<IGlobalState, IConfig | undefined>(getMobileBranding);
}
export function usePixelsBranding(): IBranding['pixels'] | undefined {
  return useSelector<IGlobalState, IBranding['pixels'] | undefined>(getPixelsBranding);
}
export function useDeviceBranding(): IConfig | undefined {
  const isMobile = useIsMobile();
  const mobileBranding = useMobileBranding();
  const desktopBranding = useDesktopBranding();
  return isMobile ? mobileBranding : desktopBranding;
}

export function useIsBrandingScrolledDown(): IState['scrolledDown'] {
  return useSelector<IGlobalState, IState['scrolledDown']>(getIsScrolledDown);
}
