import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { IAbTestingInfo } from '@sravni/ab-testing-sdk/lib/node';

import type { IGlobalState } from '@src/reducers/index';

type MetadataState = {
  isReviewer: boolean;
  abTestingInfo: IAbTestingInfo | null;
};

const initialState: MetadataState = {
  isReviewer: false,
  abTestingInfo: null,
};

export const setReviewer = createAction<boolean>('metadata/setReviewer');
export const setAbTestingInfo = createAction<IAbTestingInfo>('metadata/setAbTestinfInfo');

export const metadataReducer = createReducer<MetadataState>({}, initialState)
  .on(setReviewer, (state, payload) => ({
    ...state,
    isReviewer: payload,
  }))
  .on(setAbTestingInfo, (state, payload) => ({
    ...state,
    abTestingInfo: payload,
  }));

export function useIsReviewer() {
  return useSelector((state: IGlobalState) => state.metadata.isReviewer);
}
