import type { CookieController, IRealTimeCookie, useCookie as TUseCookie } from '@sravni/growth/cookie';
import { CookieClient, factoryCookie as factoryCookieBase } from '@sravni/growth/cookie';
import { isServer } from '@sravni/growth/utils/isServer';

export enum CookieKey {
  _ga = '_ga',
  __utmz = '__utmz',
  _ym_uid = '_ym_uid',
  HIDE_EMAIL_BANNER = 'HIDE_EMAIL_BANNER',
  HIDE_MOBILE_BANNER = 'HIDE_MOBILE_BANNER',
  HIDE_POPUP_BEST_OFFERS = 'HIDE_POPUP_BEST_OFFERS',
  HIDE_SHARE_BANNER = 'HIDE_SHARE_BANNER',
  HIDE_EXIT_INTENT_POPUP = 'HIDE_EXIT_INTENT_POPUP',
  _SL_ = '_SL_',
  clid = 'clid',
  USER_ID = 'userId',
  _PN_ = '_PN_',
  aff_click_id = 'aff_click_id',
  aff_id = 'aff_id',
  amount = 'amount',
  term = 'term',
  mindboxUUid = 'mindboxDeviceUUID',
}

export type CookieModel = Partial<Record<CookieKey, string>>;

// eslint-disable-next-line import/no-mutable-exports,@typescript-eslint/init-declarations
export let Cookie: CookieController<CookieModel>;
// eslint-disable-next-line import/no-mutable-exports,@typescript-eslint/init-declarations
let RealTimeCookie: IRealTimeCookie<CookieModel>;
// eslint-disable-next-line import/no-mutable-exports,@typescript-eslint/init-declarations
export let useCookie: TUseCookie<CookieModel>;

export const factoryCookie = (cookieController: CookieController<CookieModel>) => {
  ({ Cookie, RealTimeCookie, useCookie } = factoryCookieBase(cookieController));
};

if (!isServer()) {
  factoryCookie(new CookieClient());
}
