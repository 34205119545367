export const FULL_DEAL_ONE_LINK =
  'https://sravni.onelink.me/cyn1?pid=sravni.ru&af_channel=display&c=mfo_referral_web_to_app&af_adset=main_banner&is_retargeting=true&af_android_store_csl=mfo_full_deal&af_reengagement_window=30d';

export const FULL_DEAL_MOBILE_APP_LINKS = {
  FROM_MODAL_OFFER: 'https://sravni.go2cloud.org/aff_c?offer_id=2443&aff_id=2&url_id=13311',
  FROM_REFERAL_VITRINA: 'https://sravni.go2cloud.org/aff_c?offer_id=2443&aff_id=2&url_id=13316',
  FROM_LANDING: 'https://sravni.go2cloud.org/aff_c?offer_id=2443&aff_id=2&url_id=13317',
};

export const WEB_FULL_DEAL_LINK = '/mfo/credit/';

export const FULL_DEAL_QUERY_PARAMS = {
  term: 'term',
  amount: 'amount',
  showAuth: 'showAuth',
  referrer: 'backwards_url',
  placement: 'placement',
};
