import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { IReviewItem } from '@sravni/react-seo';

import type { IGlobalState } from '@src/reducers/index';

import DataStatus from '../enums/DataStatus';

type IState = {
  data: IReviewItem[];
  status: DataStatus;
};

const initialState: IState = {
  data: [],
  status: DataStatus.Default,
};

export const setReviews = createAction<IState>('reviews/set');

export const reviewsReducer = createReducer<IState>({}, initialState).on(setReviews, (_state, payload) => payload);

const selectReviewsState = (state: IGlobalState): IReviewItem[] => state.reviews.data;

export const useReviewsState = () => useSelector(selectReviewsState);
