import { safeJSONParse } from './safeJSONParse';

export enum StorageKeys {
  /**  */
  CENTER = 'centers',
  /**  */
  REGIONS = 'regions',
  /**  */
  LIST_CLICKED_PRODUCTS = 'listClickedProducts',
}

export const storage = {
  setItem(key: StorageKeys, value: string) {
    localStorage.setItem(key, value);
  },
  getItem<K>(key: StorageKeys): K | undefined {
    const storageValue = localStorage.getItem(key);
    if (storageValue === null) {
      return undefined;
    }
    return safeJSONParse<K>(storageValue);
  },
  removeItem(key: StorageKeys) {
    localStorage.removeItem(key);
  },
};
