export const SHOW_OFFERS_BUTTON_TEXT = 'Показать предложения';

export const DAYS_IN_MONTH = 30;

export const TERM_MIN = 5;
export const TERM_MAX = 365;
export const TERM_MONTH_MIN_IN_DAYS = 60;
export const TERM_MONTH_MIN = 2;
export const TERM_MONTH_MAX = 12;
export const TERM_DEFAULT = 10;
export const TERM_DEFAULT_AB_TEST = 14;
export const TERM_ERROR_MIN = 'Минимальный срок займа от 5 дней';
export const TERM_ERROR_MAX = 'Максимальный срок займа до 365 дней';
export const TERM_LABEL = 'На срок';
export const TERM_TEXT = 'от 5 до 365 дней';
export const TERM_MONTH_TEXT = 'от 2 до 12 месяцев';

export const AMOUNT_MIN_IL_VALUE = 15000;
export const AMOUNT_MIN = 3000;
export const AMOUNT_MAX = 100000;
export const AMOUNT_DEFAULT = 10000;
export const AMOUNT_DEFAULT_AB_TEST = 7000;
export const AMOUNT_SLIDER_STEP = 1000;
export const AMOUNT_ERROR_MIN = 'Минимальная сумма от 3 000 рублей';
export const AMOUNT_ERROR_MAX = 'Максимальная сумма до 100 000 рублей';
export const AMOUNT_LABEL = 'Мне нужно';
export const AMOUNT_TEXT = 'от 3 000 до 100 000 ₽';

export const TERM_MONTH_OPTIONS = [
  { label: '2 месяца', value: 60 },
  { label: '3 месяца', value: 90 },
  { label: '4 месяца', value: 120 },
  { label: '5 месяцев', value: 150 },
  { label: '6 месяцев', value: 180 },
  { label: '7 месяцев', value: 210 },
  { label: '8 месяцев', value: 240 },
  { label: '9 месяцев', value: 270 },
  { label: '10 месяцев', value: 300 },
  { label: '11 месяцев', value: 330 },
  { label: '1 год', value: 365 },
];
