/**
 * Safe parsing json
 * @param str is json
 * @param handler if need to handle some error
 */
export function safeJSONParse<K>(str: string, handler?: (error: Error, value?: string) => void): K | undefined {
  try {
    return JSON.parse(str);
  } catch (error) {
    if (handler) {
      handler(error, str);
    }
    console.warn(error);
    return undefined;
  }
}
