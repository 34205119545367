// eslint-disable-next-line no-shadow
export enum Currency {
  RUB = 'RUB',
  EUR = 'EUR',
  USD = 'USD',
  KZT = 'KZT',
}

export const currencyLabel: Record<Currency, string> = {
  [Currency.RUB]: 'РУБ',
  [Currency.EUR]: 'ЕВРО',
  [Currency.USD]: 'ДОЛЛАР',
  [Currency.KZT]: 'ТЕНГЕ',
};
