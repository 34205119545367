import { createAction, createReducer } from 'redux-act';

import { FULL_DEAL_FORM_AMOUNT_DEFAULT, FULL_DEAL_FORM_TERM_DEFAULT } from '../config';
import type { FullDealState, UpdateFullDealCalculatorPayload } from '../types';

export const initialState: FullDealState = {
  calculator: {
    amount: FULL_DEAL_FORM_AMOUNT_DEFAULT,
    term: FULL_DEAL_FORM_TERM_DEFAULT,
  },
};

export const updateFullDealCalculator = createAction<UpdateFullDealCalculatorPayload>('fullDealCalculator/update');

export const fullDealReducer = createReducer<FullDealState>({}, initialState).on(
  updateFullDealCalculator,
  (state, payload) => ({
    ...state,
    calculator: {
      ...state.calculator,
      ...payload,
    },
  }),
);
