export const API_PREFIX = '/zaimy/api';

export const BFF_API_ROUTES = {
  saveUserPhone: '/save-phone',
  getIndexedLocationsRoute: '/metadata/index',
  getRegionalCentersRoute: '/location/centers',
  getRegionsRoute: '/location/regions',
  webVitalsRoute: '/web/vitals',

  // todo: на данный момент не используется, но в будущем может понадобится если будет тихая авторизация
  getUserCode: '/signing/code/',
  getUserVerify: '/signing/verify/',
  getUserProfileRoute: '/user/profile',
} as const;

export const BFF_PROXY_API: Record<keyof typeof BFF_API_ROUTES, string> = {
  saveUserPhone: `${API_PREFIX}${BFF_API_ROUTES.saveUserPhone}`,
  getIndexedLocationsRoute: `${API_PREFIX}${BFF_API_ROUTES.getIndexedLocationsRoute}`,
  getRegionalCentersRoute: `${API_PREFIX}${BFF_API_ROUTES.getRegionalCentersRoute}`,
  getRegionsRoute: `${API_PREFIX}${BFF_API_ROUTES.getRegionsRoute}`,
  getUserCode: `${API_PREFIX}${BFF_API_ROUTES.getUserCode}`,
  getUserVerify: `${API_PREFIX}${BFF_API_ROUTES.getUserVerify}`,
  webVitalsRoute: `${API_PREFIX}${BFF_API_ROUTES.webVitalsRoute}`,
  getUserProfileRoute: `${API_PREFIX}${BFF_API_ROUTES.getUserProfileRoute}`,
};
