import type { NextComponentType } from 'next';
import type { NextWebVitalsMetric } from 'next/app';
import type { NextRouter } from 'next/router';
import React from 'react';

import type { IAbTestingInfo } from '@sravni/ab-testing-sdk/dist/types/node';
import type { ThemeNames } from '@sravni/design-system-theme';
import { ThemeName } from '@sravni/design-system-theme';
// eslint-disable-next-line import/no-unassigned-import
import '@sravni/design-system-theme/lib/globalStyles.css';
import { CookieClient, CookieServer } from '@sravni/growth/cookie';
import type { IDeviceInfo } from '@sravni/koa-utils/lib/middlewares/device';
import { AbTestingProvider, DeviceInfoProvider, ThemeProvider } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import { DEFAULT_REGION_ROUTE } from '@src/constants/filters';
import { checkTrailingSlash } from '@src/helpers/handleRoutes';
import { factoryCookie } from '@src/modules/cookie';
import type { IGlobalState } from '@src/reducers';
import { currentLocationSelector } from '@src/reducers/locations';
import { isServer } from '@src/utils';
import { ConfigProvider, StoreProvider } from 'app/providers';

import { sendWebVitals } from '../services/webVitals';
import { getOrCreateStore } from '../store/getOrCreateStore';
import { initialDispatcher } from '../store/initialDispatcher';
// eslint-disable-next-line import/no-unassigned-import
import '../styles/global.scss';

interface IAppProps {
  Component: NextComponentType;
  pageProps: Record<string, unknown>;
  router: NextRouter;
  initialReduxState: IGlobalState;
  mediaInfo: IDeviceInfo;
  theme: ThemeNames;
  abTestingInfo?: IAbTestingInfo;
}

// eslint-disable-next-line max-statements
const MyApp = (props: IAppProps) => {
  const { Component, pageProps, initialReduxState, mediaInfo, abTestingInfo, theme } = props;

  return (
    <StoreProvider initialReduxState={initialReduxState}>
      <DeviceInfoProvider initialInfo={mediaInfo}>
        <AbTestingProvider initialValue={abTestingInfo} mode="uncontrolled">
          <ThemeProvider initialTheme={theme}>
            <ConfigProvider>
              <Component {...pageProps} />
            </ConfigProvider>
          </ThemeProvider>
        </AbTestingProvider>
      </DeviceInfoProvider>
    </StoreProvider>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: Application.ReduxAppContext) => {
  if (isServer) checkTrailingSlash(ctx);

  ctx.cookie = isServer && ctx.res && ctx.req ? new CookieServer({ req: ctx.req, res: ctx.res }) : new CookieClient();

  const [pathname] = ctx.asPath?.split('?') || [];

  ctx.store = initialDispatcher(ctx, getOrCreateStore());

  factoryCookie(ctx.cookie);

  const location = currentLocationSelector(ctx.store.getState());

  let deviceInfo: IDeviceInfo = { phone: false, tablet: false, isAppleMobile: false };
  let theme: ThemeNames = ThemeName.lager;

  if (ctx.req?.__DEVICE_INFO__) {
    deviceInfo = ctx.req.__DEVICE_INFO__;
  }

  ctx.params = {
    locationRoute: location?.route || DEFAULT_REGION_ROUTE,
    pathname,
    mediaInfo: deviceInfo,
  };

  if (ctx.req?.__THEME__) {
    theme = ctx.req.__THEME__;
  }

  const initialPageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : ({} as any);
  const abTestingInfo = isServer && ctx.req.__AB_TESTING__ ? ctx.req.__AB_TESTING__ : undefined;

  return {
    pageProps: initialPageProps,
    initialReduxState: ctx.store.getState(),
    mediaInfo: ctx.params.mediaInfo,
    theme,
    abTestingInfo,
  };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  sendWebVitals(metric);
}

export default MyApp;
