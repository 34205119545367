import qs from 'querystring';

import type { NextRouter } from 'next/router';
import Router from 'next/router';

import { getRouteParams } from '../helpers/query';
import {
  appWhiteLabelDeniedRoute,
  brandingPageRoute,
  calculatorPageRoute,
  kazakhstanSeoListRoutes,
  kzContextPageRoute,
  landingPageRoute,
  naKartuPageRoute,
  newSpecOffersLandingPageRoute,
  partner2GisPageRoute,
  promotionPageRoute,
  specOffersLandingPageRoute,
  webWhiteLabelDeniedRoute,
  whiteLabelRoute,
} from '../shared/constants/pageRoutes';

const paramRegExp = /(:[^\/&\?]*\??)(\/|$)/g;

const escapeRegExp = (str: string) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterValues = <TType extends { [k: string]: any }>(
  predicate: (key: string, value: string) => boolean,
  params: TType,
): Partial<TType> =>
  Object.entries(params).reduce((acc, [k, v]) => (predicate(k, v) ? Object.assign(acc, { [k]: v }) : acc), {});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toString = (params: Record<string, any>, prefix = '?') => {
  const queryString = qs.stringify(params);

  return queryString ? prefix + queryString : '';
};

const replaceWithParams = (pattern: string, params: Record<string, string>) =>
  Object.keys(params)
    .reduce(
      (acc, param) => acc.replace(new RegExp(`:${escapeRegExp(param)}\\??(?=(\\/|$|\\?))`), String(params[param])),
      pattern,
    )
    .replace(paramRegExp, '');

const addInitialSlash = (str: string) => (str.match(/^\//) ? str : `/${str}`);

interface ILinkParams {
  organizationAlias?: string;
  productAlias?: string;
}

export const createLinkProps = (page = '', pattern = '', params: ILinkParams = {}) => {
  const inlineParams = filterValues((key) => !!pattern.match(`:${key}`), params);

  return {
    href: `${addInitialSlash(page)}${toString(inlineParams)}`,
    as: `${replaceWithParams(pattern, inlineParams)}`,
  };
};

export const easyQueryReplace = (router: NextRouter, query: IQueryParams) => {
  const routerParams = getRouteParams(router.query);
  const [asPath] = router.asPath.split('?');
  const url = `${router.route}${toString({ ...routerParams, ...query })}`;
  const as = `${asPath}${toString(query)}`;

  return Router.replace(url, as, { scroll: false });
};

export const preparePath = (route: string) => {
  const [pathname] = route.split('?');
  return pathname.endsWith('/') ? pathname : `${pathname}/`;
};

const replaceDoubleSlash = (str: string) => str.replace(/\/{2,}/g, '/');

export const prepareSeoUrl = (pathname: string, paramRegion?: string) => {
  const url = preparePath(pathname);

  if (!paramRegion) {
    return url;
  }

  return replaceDoubleSlash(url.replace(paramRegion, ''));
};

interface IQueryParams {
  paramRegion?: string;
}

const getNonRegionUrl = (path: string, query: IQueryParams) => {
  const { paramRegion } = query;
  const prepared = preparePath(path);

  return paramRegion ? replaceDoubleSlash(prepared.replace(paramRegion, '')) : prepared;
};

export const isMainPage = (path: string, query: IQueryParams): boolean => {
  const testUrl = getNonRegionUrl(path, query);

  return /^\/zaimy\/?$/.test(testUrl);
};

export const isSpecialPage = (path: string, query: IQueryParams): boolean => {
  const testUrl = getNonRegionUrl(path, query);

  return /^\/zaimy\/special\/?$/.test(testUrl);
};

export const isKZPage = (path: string) => path.startsWith('/kz/');

export const isKZContextPage = (path: string) => path.startsWith(kzContextPageRoute);
export const isWhiteLabelPage = (path: string): boolean => path.includes(whiteLabelRoute);
export const isWhiteLabelDeniedPage = (path: string): boolean =>
  path === webWhiteLabelDeniedRoute || path === appWhiteLabelDeniedRoute;

export const isLandingPage = (path: string): boolean => path.startsWith(landingPageRoute);

export const isSpecialPageWithoutQuery = (path = ''): boolean => path.includes(specOffersLandingPageRoute);

export const isKZMainPage = (path: string) => path === '/kz/zaimy/';

export const isKZLandingPage = (path: string): boolean =>
  [...kazakhstanSeoListRoutes, kzContextPageRoute].some((route) => path === route);

export const isNaKartuPage = (path: string): boolean => path === naKartuPageRoute;

export const isPartner2GisPage = (path: string): boolean => path === partner2GisPageRoute;

export const isKalkuljatorPage = (path: string): boolean => path === calculatorPageRoute;

export const is61DaysBestDealsPage = (path: string): boolean => path === brandingPageRoute;

export const isProverennyeZaymyPage = (path: string): boolean =>
  path.startsWith('/zaimy/proverennyye-zaymy-do-30-000/');

export const isBezProtsentovPage = (path: string): boolean => path.startsWith('/zaimy/bez-protsentov/');
