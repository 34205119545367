import type { DictionaryAlias } from '../@types/dictionary';

export const FILTERS_DICTIONARIES_KEYS: Record<string, DictionaryAlias> = {
  DOCUMENTS: 'microCreditsDocuments',
  DEMANDS: 'demands',
  SOLVENCY_PROOF: 'solvencyProof',
  OBTAINING_METHOD: 'microCreditObtainingMethod',
  REGISTRATION_WAY: 'registrationWay',
  SECURITY: 'security',
  GUARANTEE: 'guarantee',
  PAYMENTS_FREQUENCY: 'paymentsFrequency',
  INSURANCES: 'insurance',
  BENEFITS: 'microCreditBenefits',
};
