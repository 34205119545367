import getConfig from 'next/config';

import type { ILocation } from '../reducers/locations';
import { BFF_PROXY_API } from '../shared/constants/apiRoutes';
import client from '../utils/client';

export const loadMicroCreditsRegions = async (params?: { adsOnly?: boolean; seoActiveCitiesUrl?: string }) => {
  const { data } = await client.get(`${getConfig().publicRuntimeConfig.publicUrl}/v1/microcredit/regions/`, {
    params,
    timeout: 1500,
  });

  return data;
};

export const loadRegions = async (): Promise<ILocation[]> => {
  const { data } = await client.get(BFF_PROXY_API.getRegionsRoute);

  return data;
};

export const loadRegionalCenters = async (): Promise<ILocation[]> => {
  const { data } = await client.get(BFF_PROXY_API.getRegionalCentersRoute);

  return data;
};
