import { createAction, createReducer } from 'redux-act';

import type { IOrganization } from '@src/@types/microcredits';

type IState = IOrganization[] | null;

const initialState: IState = null;

export const setOrganizations = createAction<{ items: IOrganization[] }>('organizations/set');

export const organizationsReducer = createReducer<IState>({}, initialState).on(
  setOrganizations,
  (_, data) => data?.items || [],
);
