import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { IPublicUser } from '@sravni/types/lib/auth';

import type { IGlobalState } from '.';

type IState = {
  loggedIn: boolean;
  account?: IPublicUser;
};

const initialState: IState = {
  loggedIn: false,
};

export const authorizeUser = createAction<IPublicUser>('user/authorize');
const destroyUser = createAction('user/destroy');

export const userReducer = createReducer<IState>({}, initialState)
  .on(authorizeUser, (state, payload) => ({
    ...state,
    loggedIn: true,
    account: payload,
  }))
  .on(destroyUser, () => ({
    loggedIn: false,
  }));

const userSelector = (state: IGlobalState) => (state.user.loggedIn ? state.user.account : null);

export const useSelectUser = () => useSelector(userSelector);
