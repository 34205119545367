import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { UpdateFullDealCalculatorPayload } from '../types';

import { updateFullDealCalculator } from './fullDeal.reducer';
import { selectFullDealCalculator } from './fullDeal.selectors';

export const useSelectFullDealCalculator = () => useSelector(selectFullDealCalculator);

export const useUpdateFullDealCalculator = () => {
  const dispatch = useDispatch();

  return useCallback(
    (payload: UpdateFullDealCalculatorPayload) => {
      dispatch(updateFullDealCalculator(payload));
    },
    [dispatch],
  );
};
