export enum ListKeys {
  LIST = 'list',
  ONLINE = 'online',
  PROMOTION = 'promotion',
  LANDING = 'landing',
  LEADSSU = 'leadssu_promo',
  GURULEADS = 'guruleads_promo',
  BRANDING = 'branding',
  CALCULATOR = 'calculator',
  BESTPRODUCTS = 'best_products',
  BESTOFFERS = 'best_offers',
  NOVINKI = 'novinki',
  MOBILE_POPUP = 'mobile_popup',
  PARTNERS = 'partners',
  WL_PAGE = 'wl_app',
  WL_DENIED_PAGE = 'wl_denied_app',
  SIDEBAR_BANNER = 'sidebar_banner',
  SPEC_OFFERS = 'spec_offers',
  ZAIMY_POD_PTS = 'zaimy-pod-pts',
  KAZAKHSTAN = 'kazakhstan',
  NEW_SPECIAL = 'new_special',
  MFO_PARTNER_2GIS = 'mfo-partner-2gis',
  SPEC_OFFERS_BY_IDS = 'spec_offers_by_ids',
}
