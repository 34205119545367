import type * as Microcredits from '@src/@types/microcredits';
import { getMicrocreditsRequestUrl } from '@src/helpers/getMicrocreditsRequestUrl';
import { filterQueryParams } from '@src/helpers/microcredits';

import client from '../utils/client';

export const loadPopupList = async (params: Microcredits.List.IReq) => {
  const url = getMicrocreditsRequestUrl('/popupList/');

  const query = filterQueryParams(params);
  const { data } = await client.get<Microcredits.List.IRes>(url, {
    params: query,
    timeout: 10 * 1000,
  });

  return data;
};

export const loadListByIds = async (params: Microcredits.List.IReq) => {
  const url = getMicrocreditsRequestUrl('/list/by-ids/');

  const query = filterQueryParams(params);
  const { data } = await client.get<Microcredits.List.IRes>(url, {
    params: query,
    timeout: 10 * 1000,
  });

  return data;
};

export const loadList = async (params: Microcredits.List.IReq) => {
  const url = getMicrocreditsRequestUrl('/list-ab/');

  const query = filterQueryParams(params);
  const { data } = await client.get<Microcredits.List.IRes>(url, {
    params: query,
    timeout: 10 * 1000,
  });

  return data;
};

export const loadCalculatorList = async (params: Microcredits.List.IReq) => {
  const url = getMicrocreditsRequestUrl('/calculator/list/');

  const query = filterQueryParams(params);

  const { data } = await client.get<Microcredits.List.IRes>(url, { params: query, timeout: 10 * 1000 });

  return data;
};

export const loadLandingList = async (params: Microcredits.LandingItems.Req) => {
  const { currency, limit, location, skip, group, newOnly, sort, off, channel } = params;
  const url = getMicrocreditsRequestUrl('/landing/list/');

  const { data } = await client.get<Microcredits.LandingItems.Res>(url, {
    params: filterQueryParams({
      currency,
      limit,
      location,
      skip,
      group,
      newOnly,
      sort,
      off,
      channel,
    }) as Microcredits.LandingItems.Req,
    timeout: 1500,
  });

  return data;
};
