import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { ISiteSettings } from '@sravni/types/lib/sitesettings';

import type StatusData from '@src/@types/StatusData';

import DataStatus from '../enums/DataStatus';

import type { IGlobalState } from '.';

type IState = StatusData<{ header: Partial<ISiteSettings['header']>; footer: Partial<ISiteSettings['footer']> }>;

const initialState: IState = {
  data: {
    header: {
      menu: undefined,
      mobileMenu: undefined,
    },
    footer: {
      menu: undefined,
      socialLinks: undefined,
      support: undefined,
    },
  },
  status: DataStatus.Default,
};

export const setSettings = createAction<IState>('set sitesettings');

export const siteSettingsReducer = createReducer({}, initialState).on(setSettings, (_state, payload) => payload);

const selectFooterSettings = (state: IGlobalState) => state?.siteSettings?.data?.footer;
const selectHeaderSettings = (state: IGlobalState) => state?.siteSettings?.data?.header;

export const useSelectFooterSettings = () => useSelector(selectFooterSettings);
export const useSelectHeaderSettings = () => useSelector(selectHeaderSettings);
