export const FULL_DEAL_ENTRY_POINT_TEXTS = {
  heading: 'Не хотите искать займ?',
  hasFormHeading: 'Ваша заявка на займ',
  hasOfferHeading: 'Вам одобрили займ',
  description: 'Заполните одну анкету, а мы найдем займ с одобрением',
  redirectButtonText: 'Отправить заявку',
  mobileAppButtonText: 'Мои займы',
  newDealButtonText: 'Новый займ',
  completeDeal: 'Продолжить',
  stickyBlockHasFormTitle: 'Заявка на займ',
  stickyBlockHasOfferTitle: 'Одобренный займ',
  pickUpLoan: 'Подобрать займ',
} as const;
